/*
  Array.from and Object.assign are not correctly polyfilled in oidc-client (for IE 11),
  so we have to manually import the polyfills first.
*/
import 'core-js/es/array/from';
import 'core-js/es/object/assign';
import axios from 'axios';
import { UserManager } from 'oidc-client-ts';
import { LIFEWAY_ACCOUNTS_URL, AUTH_ROUTE, PATH_LOGOUT_CALLBACK } from '../constants';


const scope = [
  'openid',
  'basic-profile',
  'auth-logininfo',
  'mlc-gateway',
  'auth-credentialmgmt',
  'auth-thirdpartygrants-read',
  'auth-thirdpartygrants-write',
  'auth-thirdpartyidpmgmt',
  'invitation',
  'auth-devicemgmt',
].join(' ');

export const getOffset = () =>
  axios.get(`${LIFEWAY_ACCOUNTS_URL}/oauth2/servertime`)
    .then(({ data }) => parseInt(`${data.unixtime - (Date.now() / 1000)}`))
    .catch(_ => undefined);

export default new UserManager({
  authority: LIFEWAY_ACCOUNTS_URL,
  client_id: '05374379-D758-4B45-A856-7FE3B3183807',
  redirect_uri: `${window.location.origin}${AUTH_ROUTE}`,
  response_type: 'code',
  scope,
  post_logout_redirect_uri: `${window.location.origin}${PATH_LOGOUT_CALLBACK}`,
  automaticSilentRenew: true,
  silent_redirect_uri: `${window.location.origin}/oauth2/silent.html`,
  silentRequestTimeoutInSeconds: 30000,
  accessTokenExpiringNotificationTimeInSeconds: 60,
  stopCheckSessionOnError: false,
  loadUserInfo: true,
  offsetSeconds: getOffset()
});
