function envLocation() {
  return `${location.protocol}//${location.hostname}${location.port ? `:${location.port}` : ''}`;
}

const LOCAL_STORAGE_KEY = 'location';

export const saveToLocalStorage = (location) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(location));
};

export const popFromLocalStorage = () => {
  const previousLocation = localStorage.getItem(LOCAL_STORAGE_KEY);
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  return previousLocation && JSON.parse(previousLocation);
};

export default envLocation;
